import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Ui } from 'src/constant/Env';
import { Page } from 'src/constant/Page';
import { ThemeContext } from 'src/context/ThemeContext';
import IcCircle from 'src/image/ic-circle.svg';
import { RootState } from 'src/redux/store';

type NavItem = {
  path: Page;
  locale: string;
  img: string;
};

const useNav = (): NavItem[] => {
  const {
    theme,
    image: { IcAccount, IcOrder, IcProperty, IcSupport, IcTrade, IcAgency, IcReport },
  } = useContext(ThemeContext);
  const { accountInfo } = useSelector((root: RootState) => root.auth);

  return useMemo(() => {
    if (Ui === 'admin')
      return [
        { path: Page.Home, locale: 'nav.home', img: IcCircle },
        { path: Page.UserAsk, locale: 'nav.userAsk', img: IcCircle },
        { path: Page.UserBid, locale: 'nav.userBid', img: IcCircle },
        { path: Page.UserAdmin, locale: 'nav.userAdmin', img: IcCircle },
        { path: Page.Fill, locale: 'nav.fill', img: IcCircle },
        { path: Page.Trade, locale: 'nav.trade', img: IcCircle },
        { path: Page.Order, locale: 'nav.order', img: IcCircle },
        { path: Page.Balance, locale: 'nav.balance', img: IcCircle },
        { path: Page.BalanceStatistics, locale: 'nav.balanceStatistics', img: IcCircle },
        { path: Page.WithdrawalRecord, locale: 'nav.withdrawal', img: IcCircle },
        { path: Page.DepositRecord, locale: 'nav.deposit', img: IcCircle },
        { path: Page.ParentRebate, locale: 'nav.parentRebate', img: IcCircle },
        { path: Page.AgentRebate, locale: 'nav.agentRebate', img: IcCircle },
        { path: Page.Commission, locale: 'nav.commission', img: IcCircle },
        { path: Page.Setting, locale: 'nav.setting', img: IcCircle },
        { path: Page.Announcement, locale: 'nav.announcement', img: IcCircle },
        { path: Page.Bank, locale: 'nav.bank', img: IcCircle },
        { path: Page.Message, locale: 'nav.message', img: IcCircle },
        { path: Page.AskerLimit, locale: 'nav.askerLimit', img: IcCircle },
        { path: Page.Account, locale: 'nav.account', img: IcCircle },
      ];
    else if (Ui === 'seller') {
      const temp = [
        { path: Page.Trade, locale: 'nav.trade', img: IcTrade },
        { path: Page.Order, locale: 'nav.order', img: IcOrder },
        { path: Page.Property, locale: 'nav.property', img: IcProperty },
        { path: Page.Account, locale: 'nav.account', img: IcAccount },
        { path: Page.Report, locale: 'nav.report', img: IcReport },
        { path: Page.Support, locale: 'nav.support', img: IcSupport },
      ];
      if (accountInfo?.isKidsEditable === true) {
        temp.splice(4, 0, { path: Page.Agency, locale: 'nav.agency', img: IcAgency });
        temp.splice(6, 0, { path: Page.ReportCommission, locale: 'nav.commission', img: IcReport });
      }

      return temp;
    }

    // buyer
    return [
      { path: Page.Trade, locale: 'nav.trade', img: IcTrade },
      { path: Page.Order, locale: 'nav.order', img: IcOrder },
      { path: Page.Property, locale: 'nav.property', img: IcProperty },
      { path: Page.Account, locale: 'nav.account', img: IcAccount },
      { path: Page.Support, locale: 'nav.support', img: IcSupport },
    ];
  }, [theme, accountInfo]);
};

export default useNav;
